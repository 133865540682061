import { PitTicketsUtilitiesError } from './errorMessages'
import moment from 'moment';
import { convertDateToFirestoreTimestamp,
  convertDateStringToFirestoreTimestamp, 
  convertMapToFirestoreTimestamp } from './FirestoreUtilities'

export default class PitTicketsUtilities {

  constructor(firebase, db, pitTicketsRefs) {
    this.firebase = firebase
    this.db = db
    this.pitTicketsRefs = pitTicketsRefs
    this.units = [
      "Tons",
      "Yards",
      ""
    ]
  }

  convertADate(date) {
    if (!date) {
      return null
    }
    let isDate = (date instanceof Date || moment.isMoment(date))
    // JS date
    if (isDate) {
      return convertDateToFirestoreTimestamp(this.firebase, date)
    }
    // JSON representation of Firestore timestamp
    if (!((typeof date.seconds === 'undefined') && (typeof date.nanoseconds === 'undefined'))) {
      return convertMapToFirestoreTimestamp(this.firebase, date)
    }
    // must be date string
    return convertDateStringToFirestoreTimestamp(this.firebase, date)
  }

  convertDates = (pitTicket) => {
    const dateProperties = this.dateProperties()
    dateProperties.forEach(property => {
      pitTicket[property] = this.convertADate(pitTicket[property])
    })
  }

  dateProperties = () => {
    return [
      'date',
    ]
  }

  editEntryData = (data) => {
    if (!data.date) {
      throw new Error(PitTicketsUtilitiesError.dateIsRequired)
    }
    if (!data.pitName) {
      throw new Error(PitTicketsUtilitiesError.pitNameIsRequired)
    }
    if (!this.units.includes(data.units)) {
      throw new Error(PitTicketsUtilitiesError.invalidUnits)
    }
  }

  add = async (pitName, data) => {
    var modifiedData = data
    modifiedData.pitName = pitName
    modifiedData.truck = modifiedData.truck ? modifiedData.truck : ""
    modifiedData.number = modifiedData.number ? modifiedData.number : ""
    modifiedData.picture = modifiedData.picture ? modifiedData.picture : ""
    modifiedData.amount = modifiedData.amount ? modifiedData.amount : 0
    modifiedData.units = modifiedData.units ? modifiedData.units : ""
    this.editEntryData(modifiedData)
    this.convertDates(modifiedData)
    await this.db.collection('pit_tickets').add(data)
  }

  update = async (id, data) => {
    if (data.ref) {
      throw new Error(PitTicketsUtilitiesError.removeRefForUpdate)
    }
    this.editEntryData(data)
    this.convertDates(data)
    await this.db.collection('pit_tickets').doc(id).set(data).catch(error => {
      console.error("Error updating document: ", error);
    });
  }

}