import React, { useRef } from 'react';
import Button from '@material-ui/core/Button'

export default function UploadPitTicketImage(props) {

    const fileInput = useRef(null);

    const onChange = (event) => {
        props.onChange(fileInput.current.files[0])
    }

    return (
        <Button variant="contained" component="label" >
            Upload New Image
            <input type="file" hidden ref={fileInput} onChange={onChange} />
        </Button>            
    );
}