import { Day } from '@material-ui/pickers'
import { convertFirestoreTimestampToDate } from './FirestoreUtilities'

async function fetchTruckInspection(db, id) {
    const docRef = db.collection("truck_inspections").doc(id)
    const doc = await docRef.get()
    return doc.data()
}

async function fetchTruckInspectionForDriverAndDate(firebase, db, driverName, dateRange) {
    let querySnapshot = await db.collection('truck_inspections')
      .where('driver.name', '==', driverName)
      .where('inspectionDate', '>=', dateRange.startDate)
      .where('inspectionDate', '<', dateRange.endDate)
      .get()
    return querySnapshot.docs[0]
}

export {
    fetchTruckInspection,
    fetchTruckInspectionForDriverAndDate
}