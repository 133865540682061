import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField } from '@material-ui/core'
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import Image from "material-ui-image";
import UploadPitTicketImage from './UploadPitTicketImage';

DialogForPitTicket.propTypes = {
    onClose: PropTypes.func.isRequired,
    firebase: PropTypes.object.isRequired,
    db: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired
}

export default function DialogForPitTicket(props) {

    const onClose = props.onClose

    const [dialogTitle, setDialogTitle] = useState(undefined)
    const [pitTicketImageUrl, setPitTicketImageUrl] = useState(undefined)

    useEffect(async () => {
        setDialogTitle(`Pit Ticket ${props.number}`)
        const storage = getStorage();
        if (props.picture) {
          setPitTicketImageUrl(await getDownloadURL(ref(storage, `pittickets/${props.picture}`)))
        } else {
          setPitTicketImageUrl(undefined)
        }
    }, [props.ref, props.picture, props.number] )

    const onChangePitTIcketImage = async (localImageFile) => {
        const localFileExtension = localImageFile.name.split('.').pop();
        const documentRef = await props.db.collection('pit_tickets').doc(props.id)
        const documentSnapshot = await documentRef.get()
        const newImageFileName = `EUL-${documentSnapshot.data().pitName}${documentSnapshot.data().number}.${localFileExtension}`
        const storage = getStorage();
        const storageRef = ref(storage, `pittickets/${newImageFileName}`);

        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, localImageFile).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });
        documentRef.update({picture: newImageFileName})
        props.onClose()
    }

    if (!dialogTitle) return (<div></div>)

    return (
        <Dialog open={true} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
            <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <Grid container justify="center" spacing={2} >
                    <Grid item xs={4}>
                        { pitTicketImageUrl && <Image src={pitTicketImageUrl} /> }
                        { !pitTicketImageUrl && <TextField value={"No pit ticket picture available"} inputProps={{ readOnly: true }} fullWidth /> }
                        <UploadPitTicketImage onChange={onChangePitTIcketImage} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClose} >Close</Button>
            </DialogActions>
        </Dialog> 
    )

}