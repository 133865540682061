import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField } from '@material-ui/core'
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import Image from "material-ui-image";
import { convertFirestoreTimestampToDateString } from '../common/FirestoreUtilities'
import UploadOrderDeliveryImage from './UploadOrderDeliveryImage';
const momentTz = require('moment-timezone');

DialogForDeliveryInformation.propTypes = {
    onClose: PropTypes.func.isRequired,
    db: PropTypes.object.isRequired,
    firebase: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    rowColorMap: PropTypes.object.isRequired,
  }

  export default function DialogForDeliveryInformation(props) {

    const onClose = props.onClose

    const [deliveryImageUrl, setDeliveryImageUrl] = useState(undefined)
    const [pitTicketImageUrl, setPitTicketImageUrl] = useState(undefined)
    const [loadColor, setLoadColor] = useState(undefined)
    
    useEffect(async () => {
      const storage = getStorage();
      if (props.order.deliveryImagePath) {
        setDeliveryImageUrl(await getDownloadURL(ref(storage, `delivery/${props.order.deliveryImagePath}`)))
      } else {
        setDeliveryImageUrl(undefined)
      }
      findPitTicketImage(storage, props.order.pit, props.order.ticket)
      setPitTicketImageUrl(undefined)
      // if (props.order.pitTicketImagePath) {
      //   setPitTicketImageUrl(await getDownloadURL(ref(storage, `pittickets/${props.order.pitTicketImagePath}`)))
      // } else {
      //   setPitTicketImageUrl(undefined)
      // }
      setLoadColor(props.rowColorMap.get(props.order.purpose.toUpperCase()))
    }, [props.order] )

    const findPitTicketImage = (storage, pit, ticket) => {
      props.db.collection('pit_tickets')
        .where("pitName", "==", pit)
        .where("number", "==", ticket)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                // there will only be one
                const pitTicket = doc.data()
                setPitTicketImageUrl(await getDownloadURL(ref(storage, `pittickets/${pitTicket.picture}`)))
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
    }

    const onChangeOrderDeliveryImage = async (localImageFile) => {
      const localFileExtension = localImageFile.name.split('.').pop();
      const documentRef = await props.db.collection('orders').doc(props.order.ref)
      const documentSnapshot = await documentRef.get()
      const deliveryDateFirestoreTimestamp = documentSnapshot.data().actualDeliveryDate
      const deliveryDateAsJSDate = convertFirestoreTimestampToDateString(props.firebase, deliveryDateFirestoreTimestamp)
      const deliveryDateForFileName = deliveryDateAsJSDate.replace(/\//g, "-");
      const newImageFileName = `EUL-${documentSnapshot.data().deliveryAddress}${deliveryDateForFileName}.${localFileExtension}`
      const storage = getStorage();
      const storageRef = ref(storage, `delivery/${newImageFileName}`);

      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, localImageFile).then((snapshot) => {
          console.log('Uploaded a blob or file!');
      });
      documentRef.update({deliveryImagePath: newImageFileName})
      props.onClose()
  }

    return (
        <Dialog open={true} onClose={props.onClose} fullWidth={true} maxWidth={'lg'}>
          <DialogTitle id="simple-dialog-title">Delivery Information</DialogTitle>
          <DialogContent style={{backgroundColor: loadColor }}>
            <Grid container justify="center" spacing={2} >
              <Grid item xs={4}>
                <TextField
                  value={props.order.deliveryAddress} 
                  label="Delivery Address"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={props.order.deliveryLocation} 
                  label="Location"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={momentTz(props.order.actualDeliveryDate).tz("America/Chicago").format("M/D/YYYY h:mm:s A")} 
                  label="Delivery Date/Time"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={2} >
              <Grid item xs={8}>
                <TextField
                  value={props.order.deliveryNotes} 
                  label="Notes"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={2} >
              <Grid item xs={4}>
                <TextField
                  value={props.order.pit} 
                  label="Pit"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={props.order.ticket} 
                  label="Ticket"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={2} >
              <Grid item xs={4}>
                { deliveryImageUrl &&
                  <Image src={deliveryImageUrl} />
                }
                { !deliveryImageUrl && 
                  <TextField
                    value={"No delivery picture available"} 
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                }
                <UploadOrderDeliveryImage onChange={onChangeOrderDeliveryImage} />
              </Grid>
              <Grid item xs={4}>
                { pitTicketImageUrl &&
                  <Image src={pitTicketImageUrl} />
                }
                { !pitTicketImageUrl && 
                  <TextField
                    value={"No pit ticket picture available"} 
                    inputProps={{ readOnly: true }}
                    fullWidth
                  />
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={props.onClose} >Close</Button>
          </DialogActions>
    
        </Dialog>
      )

  }